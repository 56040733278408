.cdk-overlay-container {
    position: fixed; 
    z-index: 1000;
}

.cdk-overlay-container [hidden] {
    display: none !important;
}

.cdk-overlay-backdrop.ghost-overlay-backdrop {
    background-color: rgba(255, 255, 255, 0.6);
    bottom: 30;
}

.cdk-overlay-backdrop.ghost-overlay-transparent-backdrop {
    background-color: transparent;
    bottom: 30;
}

.cdk-overlay-pane.ghost-overlay-pane {
    height: 100%;
    width: 100%;
}

.cdk-overlay-pane.ghost-overlay-pane.overlay-modal {
    border-radius: 5px;
    box-shadow: 0 0 10px #00000066;
}

.cdk-overlay-pane.ghost-overlay-pane > *,
.cdk-overlay-pane.ghost-overlay-pane .overlay-navigation-wrapper {
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-flow: column;
    height: 100%;
    max-height: inherit;
    width: 100%;
}

.cdk-overlay-pane.ghost-overlay-pane.overlay-modal > * {
    border-radius: 5px;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-header {
    align-items: center;
    background-color: rgb(var(--primary-light));
    color: rgb(var(--primary-white));
    display: flex;
    flex: 0 0 45px;
    padding: 0 10px;
    width: 100%;
}

.cdk-overlay-pane.ghost-overlay-pane.overlay-modal .overlay-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cdk-overlay-pane.ghost-overlay-pane.overlay-modal .overlay-header .close {
    color: rgb(var(--primary-white));
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-header > .overlay-title {
    flex: 1 1 auto;
    text-transform: capitalize;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-header > .overlay-stats {
    flex: 0 0 auto;
    margin-left: auto;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-header > .overlay-title,
.cdk-overlay-pane.ghost-overlay-pane .overlay-header > .overlay-stats {
    font-size: 22px;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content {
    background-color: #ffffff;
    display: flex;
    flex: auto;
    flex-flow: column;
    overflow: auto;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .actions-panel {
    align-items: center;
    display: flex;
    flex: 0 0 60px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 15px;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .actions-panel > * {
    margin-right: 15px;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .actions-panel.x2 {
    flex: 0 0 120px;
    padding: 7px 15px;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .search-panel {
    border-top: 1px solid #ffffff;
    box-sizing: border-box;
    flex: 0 0 40px;
    height: 100%;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .search-panel .form-control {
    height: 100%;
    border: transparent;
    border-radius: 0;
    box-sizing: border-box;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .search-panel .form-control:focus {
    border: 2px solid #2491eb;
    box-shadow: none;
    outline: none;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .content-panel {
    flex: auto;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .content-panel.focusable {
    border: 2px solid transparent;
    box-sizing: border-box;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-content .content-panel.focusable.active {
    border: 2px solid #2491eb;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-footer {
    align-items: center;
    background-color: rgb(var(--primary-grey));
    display: flex;
    flex: 0 0 60px;
    padding: 0 10px;
    width: 100%;
}

.cdk-overlay-pane.ghost-overlay-pane .overlay-header .float-right,
.cdk-overlay-pane.ghost-overlay-pane .overlay-content .float-right,
.cdk-overlay-pane.ghost-overlay-pane .overlay-footer .float-right {
    margin-left: auto;
}

.cdk-overlay-pane.ghost-overlay-pane.overlay-modal .overlay-footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}