form .btn {
    border-radius: 5px;
}

.btn {
    font-family: ZonaProRegular, Arial, Helvetica, sans-serif;
}

.input-group-append .btn {
    border-radius: 5px;
}

.btn:not([disabled]) {
    cursor: pointer;
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
}

.btn.search-btn {
    color: white;
    margin-top: 17px;
    width: 120px;
}

.btn-primary {
    background-color: rgb(var(--primary-light));
    border-color: rgb(var(--primary-light));
    color: rgb(var(--primary-white));
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.btn-group .active {
    background-color: rgb(var(--primary-dark));
    border-color: rgb(var(--primary-dark));
    color: rgb(var(--primary-white)) !important;
}

.btn-primary:not([disabled]):hover {
    background-color: rgb(var(--primary-dark));
    border-color: rgb(var(--primary-dark));
}

.btn-outline-primary {
    border-color: rgb(var(--primary-light));
    color: rgb(var(--primary-light));
}

.btn-outline-primary.disabled {
    color: rgba(var(--primary-dark), 0.4);
}

.btn-outline-primary:disabled {
    color: rgb(var(--primary-light));
    cursor: not-allowed;
}

.btn-outline-primary:disabled:hover {
    border-color: rgb(var(--primary-light));
}

.btn-outline-primary:not([disabled]):not(.disabled).active,
.btn-outline-primary:not([disabled]):not(.disabled):active {
    border-color: rgb(var(--primary-light));
    background-color: rgb(var(--primary-light));
    color: rgb(var(--secondary-white));
}
.btn-outline-primary:not([disabled]):hover {
    border-color: rgb(var(--primary-dark));
    background-color: rgb(var(--primary-dark));
    color: rgb(var(--secondary-white));
}

.btn-outline-secondary {
    border-color: rgb(var(--secondary-dark));
    color: rgb(var(--secondary-dark));
}
.btn-outline-secondary:not([disabled]):not(.disabled).active,
.btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-outline-secondary:not([disabled]):hover {
    border-color: rgb(var(--secondary-dark));
    background-color: rgb(var(--secondary-dark));
    color: rgb(var(--secondary-white));
}

.btn-secondary {
    border-color: rgb(var(--secondary-dark));
    background-color: rgb(var(--secondary-dark));
    color: rgb(var(--secondary-white));
}

.btn-secondary:disabled {
    background-color: rgba(var(--secondary-dark), 0.6);
    border-color: rgba(var(--secondary-dark), 0.6);
    color: rgb(var(--secondary-white));
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):hover {
    border-color: rgb(var(--secondary-dark));
    background-color: rgb(var(--secondary-dark));
    color: rgb(var(--secondary-white));
}

.btn-opaque-secondary {
    background-color: rgba(255, 255, 255, 0.35);
    color: rgb(var(--primary-white));
}

.btn-opaque-secondary:not([disabled]):not(.disabled).active,
.btn-opaque-secondary:not([disabled]):not(.disabled):active,
.btn-opaque-secondary:not([disabled]):hover {
    background-color: rgb(var(--primary-white));
    color: rgb(var(--secondary-dark));
}

.btn-opaque-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 123, 123, 0.25);
}

.action-button {
    font-size: 14px;
}

.input-group-append .btn-secondary:not([disabled]),
.input-group-append .btn-secondary:not([disabled]):hover,
.input-group-append .btn-secondary:not([disabled]):not(.disabled):active {
    border-color: rgb(var(--secondary-dark));
    background-color: rgb(var(--secondary-dark));
    color: rgb(var(--secondary-white));
}

.input-group-append .btn:not(.btn-secondary):not([disabled]):hover,
.input-group-append .btn-outline-secondary:not([disabled]):not(.disabled):active {
    background-color: rgb(var(--primary-light));
    color: rgb(var(--secondary-white));
}

.btn-toolbar .btn {
    margin-right: 10px;
    margin-left: 0;
}

button.keyboard-navigation-node:focus,
button.navigation-group-node:focus {
    box-shadow:
        0 0 0 3px #ffffff,
        0 0 0 6px #2491eb;
    outline: none;
}

.checkbox { 
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex;
    flex: 0 0 40px;
    height: 37px;
    justify-content: center;
    margin-right: 5px;
    width: 40px;
}

.checkbox.selected {
    background-color: rgb(var(--primary-light));
    color: rgb(var(--primary-white));
}

.checkbox i {
    font-size: 18px;
    font-weight: 600;
}