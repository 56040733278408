@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@fortawesome/fontawesome-pro/css/all.min.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'styles/buttons.css';
@import 'styles/ghost-overlay.css';

:root {
    --primary-white: 255, 255, 255;
    --secondary-white: 252, 253, 245;
    --tertiary-white: 240, 239, 239;
    --primary-grey: 204, 204, 204;
    --primary-dark: 25, 52, 65;
    --primary-light: 63, 97, 111;
    --secondary-dark: 145, 169, 156;
    --secondary-light: 210, 219, 189;
}

@font-face {
    font-family: ZonaProHairline;
    src: url('./shared/assets/fonts/ZonaPro-Hairline.ttf');
}

@font-face {
    font-family: ZonaProLight;
    src: url('./shared/assets/fonts//ZonaPro-Light.ttf');
}

@font-face {
    font-family: ZonaProRegular;
    src: url('./shared/assets/fonts//ZonaPro-Regular.ttf');
}

@font-face {
    font-family: ZonaProBold;
    src: url('./shared/assets/fonts//ZonaPro-Bold.ttf');
}

body {
    display: block;
    font-family: ZonaProRegular, Arial, Helvetica, sans-serif !important;
    height: 100%;
    width: 100%;
    user-select: none;
}

h1 {
    font-size: 40px !important;
    font-family: ZonaProLight, Arial, Helvetica, sans-serif !important;
}

h2 {
    font-size: 30px !important;
    font-family: ZonaProLight, Arial, Helvetica, sans-serif !important;
}

h3 {
    font-size: 26px !important;
}

h4 {
    font-size: 21px !important;
}

p {
    font-size: 13px !important;
}

hr {
    border-top: 1px solid rgb(217, 223, 226) !important;
}

.top-0 {
    top: 0 !important;
}

.right-0 {
    right: 0 !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.left-0 {
    left: 0 !important;
}

.noselect {
    user-select: none;
}

.cursor-pointer {
    cursor: pointer;
}

.keyboard-shortcut {
    color: inherit;
    font-style: normal;
    font-weight: bolder;
    text-decoration: underline;
}

.overflow-hide {
    overflow: hidden;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.flex-center {
    display: flex;
    align-items: center;
}

.text-danger {
    color: #f47f7f;
}

.underline {
    text-decoration: underline;
}

.section-disabled {
    max-height: 100%;
    overflow-y: hidden;
}

.section-disabled h3 {
    display: inline-block;
}

.section-no-data .btn-outline-success {
    align-self: center;
    position: absolute;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}